import React, { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { NewsItemBackAdmin, UnifiedNews } from 'interfaces/News/News';
import {
  formatBackAdminNewsDate,
  sortNews,
  useGetCurrentNews,
} from 'helpers/news';
import { useRouter } from 'next/router';

import Link from 'next/link';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider/Slider';

import styles from './News.module.scss';
import { mainPageAnchors } from 'helpers/anchors';
import { links } from 'helpers/constants/links';
import { getNewsBackAdmin } from 'services/newsService';
import { useSelector } from 'react-redux';
import { RootState } from 'stores/store';
import { IUserStore } from 'stores/userStore';

interface NewsProps {
  showButton?: boolean;
  newsData?: NewsItemBackAdmin[];
}

const News: FC<NewsProps> = ({ newsData, showButton = true }) => {
  const { t } = useTranslation(['news', 'common']);
  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const { news } = useGetCurrentNews();

  const unifyNews = news => {
    const currentImage = news?.previewImageLocale
      ? news?.previewImageLocale[currentLanguage]
      : news?.previewImage;

    return {
      id: news.id,
      title: news.title ?? t(`news.items.${news.id}.title`),
      image: news.main_photo_url ?? currentImage,
      date: news.date,
      link: news.link ?? `${news.id}`,
    };
  };

  // новости с фронта
  const unifiedNews: UnifiedNews[] = news.map(item => unifyNews(item));

  // новости с бэка
  const [newsFromBack, setNewsFromBack] = useState(newsData);

  useEffect(() => {
    const newsItemsResponse = async () => {
      const response = await getNewsBackAdmin({
        locale: currentLanguage,
        geo: geoDataStored,
      });

      if (response?.data?.news) {
        setNewsFromBack(response?.data?.news);
      }
    };

    newsItemsResponse();
  }, [geoDataStored, currentLanguage]);

  // исправляет формат даты внутри новостей с бэка со строки на массив
  const formatedBackNewsDate = newsFromBack.map(item =>
    formatBackAdminNewsDate(item)
  );

  const unifiedNewsBack: UnifiedNews[] = formatedBackNewsDate.map(item =>
    unifyNews(item)
  );

  const newsForRender = [...unifiedNews, ...unifiedNewsBack];
  const sortedNews = useMemo(() => sortNews(newsForRender), [newsForRender]);

  return (
    <section id={mainPageAnchors.news} className={cn(styles.news, 'content')}>
      <Slider
        title={t('news.title')}
        spaceBetween={20}
        breakpoints={{
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            allowTouchMove: newsForRender.length > 2,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            allowTouchMove: newsForRender.length > 3,
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            allowTouchMove: newsForRender.length > 4,
          },
        }}
        className={styles.news__slider}
        showMoreLink={showButton ? `/${links.news}` : ''}
      >
        {sortedNews?.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <Link href={`/${links.news}/${item?.link}`}>
                <a className={cn(styles.news__item, 'link')}>
                  <div className={styles.news__image}>
                    <picture>
                      <source
                        srcSet='/images/news/slider-placeholder.jpg'
                        data-srcset={
                          item?.image.includes('http')
                            ? item?.image.replace(/'/g, '')
                            : `/images/news/${item?.image}`
                        }
                        type='image/webp'
                      />
                      <img
                        src='/images/news/slider-placeholder.jpg'
                        data-src={
                          item?.image.includes('http')
                            ? item?.image.replace(/'/g, '')
                            : `/images/news/${item?.image}`
                        }
                        alt={item?.title}
                        className='img lazyload'
                      />
                    </picture>
                  </div>
                  <div className={styles.news__info}>
                    <div
                      className={cn(styles.news__name, styles.news__nameClamp)}
                    >
                      {item.title}
                    </div>
                    <div className={styles.news__date}>
                      {`${t(`commonMonths.${item?.date[1] - 1}`, {
                        ns: 'common',
                      })} ${item?.date[0]}, ${item?.date[2]}`}
                    </div>
                  </div>
                </a>
              </Link>
            </SwiperSlide>
          );
        })}
      </Slider>
    </section>
  );
};

export default News;
