import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { ResponseProductData } from 'interfaces/Product/ProductItem';
import { handleSetErrorText } from 'helpers/hooks/form';
import { links } from 'helpers/constants/links';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';
import ProductItem from 'components/blocks/Product/ProductItem/ProductItem';
import SeeMoreLink from 'components/blocks/SeeMoreLink';
import styles from './Product.module.scss';
import productStyles from 'components/blocks/Product/ProductMain/ProductMain.module.scss';
import { useUpdateProductsWithChangeGeo } from 'helpers/hooks/products';

interface ProductArray {
  data: ResponseProductData[];
}

const Product: React.FC<ProductArray> = ({ data }) => {
  const { t } = useTranslation(['homepage', 'feedback']);
  const { setModalType, setModalMessageData } = useContext(AppContext);

  const handleErrorResult = errorMessage => {
    setModalMessageData({
      title: t('feedback.modal.errorModal.title', { ns: 'feedback' }),
      subTitle: handleSetErrorText(errorMessage, t),
      buttonText: t('feedback.modal.errorModal.buttonText', {
        ns: 'feedback',
      }),
    });
    setModalType(MODAL.message);
  };

  const { products } = useUpdateProductsWithChangeGeo({
    productsData: data,
    show_on_main_page: true,
  });

  return (
    <section id='product' className={styles.product}>
      <div className='container'>
        <div className={styles.product__title}>
          <h2 className='title-main-v2'>{t('product.title')}</h2>
        </div>
        <div
          className={cn(
            productStyles.productMain__items,
            styles.product__items
          )}
        >
          {products?.map(
            item =>
              item?.price && (
                <ProductItem
                  key={item?.id}
                  image={item?.pack_info?.pictures_urls}
                  name={item?.pack_info?.name}
                  id={item?.id}
                  notAvaliable={false}
                  setErrorMessage={handleErrorResult}
                  price={item?.price}
                />
              )
          )}
        </div>
        <SeeMoreLink link={`/${links.shop}`} text={t('product.showAll')} />
      </div>
    </section>
  );
};

export default Product;
